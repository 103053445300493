<template>
  <a-spin :spinning="ifSub">
    <div class="details">
      <div class="details-title">
        <div class="details-title-text">基础信息</div>
        <i class="details-title-text-i"></i>
      </div>
      <a-row class="details-content-rowBasicInfo">
        <a-col :span="24"><span>挂点名称：</span>{{ formItem.name || '-' }}</a-col>
        <a-col :span="24"><span>挂点key：</span>{{ formItem.ikey || '-' }}</a-col>
        <a-col :span="24"><span>所属工艺：</span>
          <template v-for="item in technologyList">
            <span v-if="item.id === formItem.technologyId">{{ item.name || '-' }}</span>
          </template>
        </a-col>
        <a-col :span="24"><span>挂点类型：</span>{{ formItem.pointType === 'point'?'点':'盒' || '-' }}</a-col>
        <a-col :span="24">
          <a-row style="padding: 0">
            <a-col style="padding: 0;color: #4f4a4a" :span="6">监测点名称：</a-col>
            <a-col style="padding: 0" :span="18" v-if="formItem.mappings">
              <div style="padding: 0; margin-bottom: 15px" v-for="(val, key) in formItem.mappings" :key="key">
                <h4 style="margin-bottom: 5px">
                  {{ val.instanceName }}
                </h4>
                <template v-for="(item) in val.idNames">
                  <a-tag color="purple" :key="item.id" style="margin-top: 5px" closable @close="handleClose(key,item.id)">
                  {{item.metricsName}}
                  </a-tag>
                </template>
              </div>
            </a-col>
          </a-row>
        </a-col>
        <!--        <a-col :span="24"><span>挂点样式：</span>{{formItem.style}}</a-col>-->
      </a-row>
    </div>
  </a-spin>
</template>

<script>
  import configVO from './common/configVO'
  import SERVICE_URLS from '@/api/service.url'
  import entityCURDCallback from '@/views/common/mixins/entityCURDCallback'
  import { pointType, systemModule } from './common/common'

  export default {
    name: 'Form',
    components: {},
    mixins: [entityCURDCallback],
    props: {
      technologyList: {
        type: Array
      }
    },
    watch: {
      selectData (val) {
        this.selectData = val
      }
    },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.equipment2.technologyPointInfo,
        initFormItem: configVO(),
        pointType: pointType(),
        systemModule: systemModule(),
        showFormItem: false,
        questionLabelCol: { span: 5 },
        questionWrapperCol: { span: 17 },
        equipmentModal: false,
        facilityModal: false,
        selectEquipmentList: [],
        selectFacilityList: [],
        technologyPointId: ''
      }
    },
    methods: {
      loadData (id) {
        this.technologyPointId = id
        this.$http(this, {
          url: SERVICE_URLS.equipment2.technologyPointInfo.view,
          params: { id },
          noTips: true,
          success: (data) => {
            this.formItem = {}
            Object.assign(this.formItem, data.body)
            console.log(this.formItem)
          }
        })
      },
      handleClose (val, id) {
        let index_n = this.formItem.mappings[val].idNames.findIndex((i) => i.id === id)
        this.formItem.mappings[val].idNames.splice(index_n, 1)
        let index_m = this.formItem.idsMappings[val].metricsIds.findIndex((i) => i === id)
        this.formItem.idsMappings[val].metricsIds.splice(index_m, 1)
         this.updateTechnologyPointIdInfo()
      },
      updateTechnologyPointIdInfo () {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.technologyPointInfo.update,
          params: { id: this.technologyPointId },
          data: this.formItem,
          noTips: true,
          success: (data) => {
            console.log(this.formItem)
            // this.loadData(this.technologyPointId)
          }
        })
      }
    }
  }
</script>

<style scoped></style>
