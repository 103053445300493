<template>
  <a-spin :spinning="ifSub">
    <a-form :form="form">
      <a-form-item label="挂点名称" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-input
          v-decorator="['name',{ rules: [{ required: true, message: '请输入挂点名称' }] }]"
          placeholder="请输入挂点名称"
        ></a-input>
      </a-form-item>
      <a-form-item label="挂点key" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-input
          v-decorator="['ikey',{ rules: [{ required: true, message: '请输入挂点key' }] }]"
          placeholder="请输入挂点key"
        ></a-input>
      </a-form-item>
      <a-form-item label="复制挂点key" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a @click="copy" v-clipboard:copy="invitationCode1"
           v-clipboard:success="copy"
           v-clipboard:error="onError">bz_box_key_ </a>
        <a style="margin-left: 10px" @click="copy" v-clipboard:copy="invitationCode2"
           v-clipboard:success="copy"
           v-clipboard:error="onError">bz_point_key_</a>
      </a-form-item>
      <a-form-item label="挂点类型" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-select :disabled="isEdit" allowClear placeholder="请选择挂点类型" v-decorator="['pointType',{ rules: [{ required: true, message: '请选择挂点类型' }] }]">
          <a-select-option v-for="item in pointType" :value="item.value" :key="item.value">{{item.name}}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="所属工艺" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-select allowClear show-search option-filter-prop="children" placeholder="请选择工艺" v-decorator="['technologyId',{ rules: [{ required: true, message: '请选择所属工艺' }] }]">
          <a-select-option v-for="item in technologyList" :value="item.id" :key="item.id" @click="technologySelectChange(item)">{{item.name}}</a-select-option>
        </a-select>
      </a-form-item>
      <!--      <a-form-item label="所属部门" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">-->
      <!--        <a-tree-select-->
      <!--          v-decorator="['department.id', { rules: [{ required: true, message: '所属部门不能为空' }] }]"-->
      <!--          :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"-->
      <!--          :treeData="orgTree"-->
      <!--          allowClear-->
      <!--          placeholder="请选择，默认当前登录用户所在部门"-->
      <!--        ></a-tree-select>-->
      <!--      </a-form-item>-->
      <!--      <a-form-item label="设备名称" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">-->
      <!--        <template v-for="(item, index) in selectEquipmentList">-->
      <!--          <a-tag>{{ item.name }}-->
      <!--            <a-icon type="close" @click="tagClose(index, 'device_info')" style="cursor: pointer"/>-->
      <!--          </a-tag>-->
      <!--        </template>-->
      <!--        <div>-->
      <!--          <a-tag :closable="false" @click="handleEquipment" color="#108ee9">-->
      <!--            <a-icon type="plus"/>-->
      <!--            选择设备-->
      <!--          </a-tag>-->
      <!--        </div>-->
      <!--      </a-form-item>-->
      <!--      <a-form-item label="挂点样式" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">-->
      <!--        <a-input-->
      <!--          v-decorator="['style']"-->
      <!--          placeholder="{color:'red'}"-->
      <!--        ></a-input>-->
      <!--      </a-form-item>-->
      <a-modal title="设备选择列表" :width="1000" :footer="null" :visible="equipmentModal" @cancel="closeModal">
        <SelectDeviceList @selectEquipmentData="selectEquipmentData" :instanceId="instanceId"></SelectDeviceList>
      </a-modal>
      <a-modal title="设备设施列表" :width="1000" :footer="null" :visible="facilityModal" @cancel="closeModal">
        <SelectFacilityList @selectFacilityData="selectFacilityData"></SelectFacilityList>
      </a-modal>
    </a-form>
  </a-spin>
</template>

<script>
  import configVO from './common/configVO'
  import SERVICE_URLS from '@/api/service.url'
  import entityCURDCallback from '@/views/common/mixins/entityCURDCallback'
  import { pointType, systemModule } from './common/common'
  import SelectDeviceList from '../../xj/vindicate/SelectDeviceList'
  import SelectFacilityList from '../../xj/vindicate/SelectFaciltyList'

  export default {
    name: 'Form',
    components: { SelectDeviceList, SelectFacilityList },
    mixins: [entityCURDCallback],
    props: {
      departmentList: {
        type: Array
      },
      technologyList: {
        type: Array
      }
    },
    watch: {
      selectData (val) {
        this.selectData = val
      }
    },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.equipment2.technologyPointInfo,
        initFormItem: configVO(),
        pointType: pointType(),
        systemModule: systemModule(),
        showFormItem: false,
        questionLabelCol: { span: 5 },
        questionWrapperCol: { span: 17 },
        equipmentModal: false,
        facilityModal: false,
        selectEquipmentList: [],
        selectFacilityList: [],
        isEdit: false,
        instanceId: null,
        orgTree: [],
        invitationCode1: 'bz_box_key_',
        invitationCode2: 'bz_point_key_'
      }
    },
    created () {
      this.getOrgTree()
    },
    methods: {
      copy (e) {
        console.log(e.text)
      },
      onError (e) {
        console.log(e)
      },
      getOrgTree () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.orgTree = data.body
            // this.form.setFieldsValue({
            //   'department.id': this.currentDeptId().organizationId + ''
            // })
          }
        })
      },
      // 把form取到的值 赋给创建的formItem
      setVOFields (values) {
        Object.assign(this.formItem, values)
        this.formItem.instanceIds = []
        const instanceIds = []
        for (let i = 0; i < this.selectEquipmentList.length; i++) {
          instanceIds.push(this.selectEquipmentList[i].id)
        }
        this.formItem.instanceIds = instanceIds
      },
      // 给form赋值
      setFields (values) {
        this.isEdit = true
        Object.assign(this.formItem, values)
        this.form.setFieldsValue({
          'ikey': values.ikey,
          'name': values.name,
          'pointType': values.pointType,
          'technologyId': values.technologyId,
          'systemModule': values.systemModule,
          'style': values.style
        })
        this.selectEquipmentList = values.instanceList
      },
      handleChange (value) {
        this.formItem.systemModule = value
        if (value === 'facility_info') {
          this.selectFacilityList = []
        } else {
          this.selectEquipmentList = []
        }
      },
      handleEquipment () {
        this.equipmentModal = true
      },
      closeModal () {
        this.facilityModal = false
        this.equipmentModal = false
      },
      tagClose (index, type) {
        if (type === 'device_info') {
          this.selectEquipmentList.splice(index, 1)
        } else {
          this.selectFacilityList.splice(index, 1)
        }
      },
      selectEquipmentData (data) {
        this.deWeightTag(data, this.selectEquipmentList)
      },
      selectFacilityData (data) {
        this.deWeightTag(data, this.selectFacilityList)
      },
      deWeightTag (data, array) {
        var ele = false
        if (array.length > 0) {
          for (let i = 0; i < array.length; i++) {
            if (array[i].id === data.id) {
              ele = true
            }
          }
          if (!ele) {
            array.push(data)
          }
        } else {
          array.push(data)
        }
      },
      technologySelectChange (item) {
        console.log(item)
        if (item.systemModule === 'device_info') {
          this.instanceId = null
        } else {
          this.instanceId = item.instanceId
        }
      }

    }
  }
</script>

<style scoped></style>
