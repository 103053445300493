export const configColumns = () => {
  return [
    {
      title: '序号',
      width: '60px',
      scopedSlots: { customRender: '_index' },
      align: 'center'
    },
    {
      title: '挂点名称',
      dataIndex: 'name',
      scopedSlots: { customRender: 'name' },
      align: 'center'
    },
    {
      title: '挂点key',
      dataIndex: 'ikey',
      scopedSlots: { customRender: 'ikey' },
      align: 'center'
    },
    {
      title: '所属工艺',
      dataIndex: 'technologyName',
      align: 'center'
    },
    {
      title: '所属部门',
      dataIndex: 'departmentName',
      align: 'center'
    },
    // {
    //   title: '绑定类型',
    //   dataIndex: 'systemModule',
    //   scopedSlots: { customRender: 'systemModule' },
    //   align: 'center'
    // },
    {
      title: '挂点类型',
      dataIndex: 'pointType',
      scopedSlots: { customRender: 'pointType' },
      align: 'center'
    },
    {
      title: '操作',
      key: 'action',
      scopedSlots: { customRender: 'action' },
      align: 'center'
    }
  ]
}
export const metricsColumns = () => {
  return [
    {
      title: '序号',
      width: '60px',
      scopedSlots: { customRender: '_index' },
      align: 'center'
    },
    {
      title: '变量名',
      dataIndex: 'enName',
      align: 'center'
    },
    {
      title: '变量别名',
      dataIndex: 'enNameAlias',
      align: 'center'
    },
    // {
    //   title: '中文别名',
    //   dataIndex: 'cnNameAlias',
    //   align: 'center',
    // },
    {
      title: '描述',
      dataIndex: 'cnName',
      align: 'center'
    }
    //
    // {
    //   title: '指标标识名',
    //   dataIndex: 'metricsConfig.distinguishedName',
    //   scopedSlots: { customRender: 'metricsConfig.distinguishedName' },
    //   align: 'center',
    // },
    // {
    //   title: '所属网关',
    //   dataIndex: 'gateway.cnName',
    //   align: 'center',
    // },
  ]
}
export const deviceColumns = () => {
  return [
    {
      title: '序号',
      width: '50',
      scopedSlots: { customRender: '_index' },
      align: 'center'
    },
    {
      title: '设备名称',
      dataIndex: 'name',
      ellipsis: true,
      key: 'name',
      align: 'center'
    },
    {
      title: '设备编号',
      dataIndex: 'no',
      ellipsis: true,
      key: 'no',
      align: 'center'
    },
    {
      title: '设备类型',
      dataIndex: 'category.name',
      ellipsis: true,
      align: 'center'
    },
    {
      title: '设备所属部门',
      dataIndex: 'department.name',
      ellipsis: true,
      align: 'center'
    }
    // {
    //   title: '安装位置',
    //   dataIndex: 'detailLocation',
    //   ellipsis: true,
    //   align: 'center',
    // },
  ]
}
export const metricsType = () => {
  return [
    { value: 'yc', name: '遥测' },
    { value: 'yx', name: '遥信' },
    { value: 'yt', name: '遥调' },
    { value: 'yk', name: '遥控' }
  ]
}

export const pointType = () => {
  return [
    { value: 'point', name: '点' },
    { value: 'box', name: '盒' }
  ]
}
export const systemModule = () => {
  return [
    { value: 'facility_info', name: '设施' },
    { value: 'device_info', name: '设备' }
  ]
}
